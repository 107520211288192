import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import { motion } from "framer-motion"
import $ from "jquery"
import Layout from "../components/layout"

import Footer from "../components/footer"
import Drawer from "../components/drawer"

import "../styles/shul.scss"

import shulLoopVideo from "../videos/51 - Shul Final Car Loop Sequence 25 fps.mp4"
import goodWoodFestival from "../videos/49 - GoodwoodFOS_VaziraniA.mp4"

import hamMenuIcon from "../images/ham_menu_icon.svg"
import hamMenuClose from "../images/ham_menu_close.svg"
import dropDownArrow from "../images/drop-down-icon.svg"

import vaziraniV from "../images/vazirani-v.png"
import vaziraniFullLogo from "../images/vazirani-full-logo-yellow.png"
import shulLogo from "../images/shul-logo-yellow.png"

import carbonTub from "../images/CARBON TUB_GOLD2.png"
import evPowertrain from "../images/EV POWERTRAIN NEW_GOLD2.png"
import bodyIllustration from "../images/BODY_ILLUSTRATION_GOLD2.png"

//React image gallery plugin
import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: 'https://i.ibb.co/Xxrf1m1/HOME-PAGE-GALLERY-CHUNKY.jpg',
    },
    {
        original: 'https://i.ibb.co/Q6kJFm5/HOME-PAGE-GALLERY-CHUNKY2.jpg',
    },
    {
        original: 'https://i.ibb.co/DWxmMkT/HOME-PAGE-GALLERY-CHUNKY3.jpg',
    },
    {
        original: 'https://i.ibb.co/JFGRwvW/HOME-PAGE-GALLERY-CHUNKYF34.jpg',
    },
];

// markup
class ShulPage extends React.Component {

    componentDidMount() {
        $("#services").hover(function () {
            $(".drop-down").toggleClass("active");
        });

        $("#services-mobile").click(function () {
            $(".drop-down").toggleClass("active");
        });
        $(".drawer-icon").click(function () {
            $(".drawer").toggleClass("active");
            $(".drawer-icon").toggleClass("close");
            $(".drawer-icon-close").toggleClass("close");
        });
    }

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <meta charset="utf-8" />
                    <title>Vazirani Automotive</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                    <meta name="theme-color" content="#1E1E24" />
                    <meta name="description" content="Redesigning the experience by giving the electric car a 'Soul'." />
                    <meta property="og:title" content="Shul | Vazirani Automotive" />
                    <meta property="og:image:secure" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
                    <meta property="og:image" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
                    <meta property="og:image:secure_url" content="https://i.ibb.co/vxZjxJP/home-thumbnail.jpg" />
                    <meta property="og:image:type" content="image/jpeg" />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="300" />

                    <style type="text/css">
                        {`@media screen and (max-width: 480px){
                    .header>.shul-vazirani-logo{
                        display: none;
                    }
                    .header>.divider{
                        display: none;
                    }
                }`}
                    </style>
                </Helmet>
                <div>
                    <img class="drawer-icon" height="17px" src={hamMenuIcon}></img>
                    <img class="drawer-icon-close" height="17px" src={hamMenuClose}></img>
                    <Drawer></Drawer>
                    <div class="wrapper">
                        <motion.div class="header" magic magicId="header">
                            <div class="shul-v-logo">
                                <Link to="/"><img src={vaziraniV}></img></Link>
                            </div>
                            <div class="nav" style={{ justifySelf: 'right' }}>
                                <Link fade to="/">Shul</Link>
                                <span class="shul-u">_</span>
                            </div>
                            {/* <div class="divider"></div> */}
                            <div class="nav">
                                <Link fade to="/ekonk">Ekonk</Link>
                                {/* <a id="services">Ekonk
                            <img class="drop-down-arrow" src={dropDownArrow}></img>
                        </a> */}
                                {/* <div class="drop-down">
                            <Link to="/design">Design</Link>
                            <div class="divider"></div>
                            <Link to="/engineering">Engineering</Link>
                        </div> */}
                            </div>
                            {/* <div class="divider"></div> */}
                            <div class="nav">
                                <Link fade to="/dico">Dico</Link>
                            </div>

                            <div class="shul-vazirani-logo">
                                <Link to="/"><img src={vaziraniFullLogo}></img></Link>                    </div>
                        </motion.div>

                        <Layout>
                            <div class="shul-hero-section">
                                <div class="we-build-sexxy">
                                    <span>We Build Sexxy</span>
                                </div>
                                <div class="video-container">
                                    <video autoPlay loop muted playsInline class="shul-car-loop" id="shul-car-loop">
                                        <source src={shulLoopVideo} type="video/mp4"></source>
                                    </video>
                                </div>
                            </div>

                            <div class="home-sec2">
                                <div class="shul-vazirani-logo">
                                    <a href=""><img src={vaziraniFullLogo}></img></a>
                                </div>
                                {/* <!-- <div class="divider"></div> --> */}
                                <p>The entire Universe, including all matter, is a matrix of infinite electrical impulses.</p>
                                <p>It’s only natural that all our transportation goes electric. Vazirani aims to help the world make this transition seamless and fun.</p>
                                <div class="divider"></div>
                            </div>

                            <div class="home-sec3">
                                <div class="shul-gallery">
                                    {/* <!-- <img src="../images/HOME PAGE GALLERY_CHUNKY.jpg"> --> */}
                                    {/* <div id="auto">
                                <div><img src="../images/HOME PAGE GALLERY_CHUNKY.jpg" alt=""></img></div>
                                <div><img src="../images/HOME PAGE GALLERY_CHUNKY2.jpg" alt=""></img></div>
                                <div><img src="../images/HOME PAGE GALLERY_CHUNKY3.jpg" alt=""></img></div>
                                <div><img src="../images/HOME PAGE GALLERY_CHUNKYF34.jpg" alt=""></img></div>
                            </div> */}
                                    <div class="shul-gallery-outline-fix"></div>
                                    <ImageGallery items={images} showThumbnails={false} showPlayButton={false} autoPlay={true} showFullscreenButton={false} lazyLoad={true} />
                                </div>


                                <div class="shul-features">
                                    <div class="shul-logo">
                                        <img src={shulLogo}></img>
                                    </div>

                                    <div class="shul-features-desc">
                                        <span>Redesigning the experience by giving the electric car a 'Soul'.</span><br></br>
                                        <span style={{ opacity: '0.5' }}>Coming soon.</span>
                                    </div>
                                    <div class="shul-features-pictures">
                                        <div>
                                            <img src={carbonTub}></img>
                                            <p>Carbon Fibre<br></br>
                                                Monocoque Cell</p>
                                        </div>
                                        <div class="divider"></div>
                                        <div>
                                            <img src={evPowertrain}></img>
                                            <p>Electric Powertrain<br></br>&gt;1000hp</p>
                                        </div>
                                        <div class="divider"></div>
                                        <div>
                                            <img src={bodyIllustration}></img>
                                            <p>Carbon Fibre<br></br>
                                                Body Work</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="home-sec4">
                                <div class="video-container">
                                    <video autoPlay loop muted playsInline class="shul-car-loop" id="shul-car-loop">
                                        <source src={goodWoodFestival} type="video/mp4"></source>
                                    </video>
                                </div>
                            </div>
                            <Footer></Footer>
                        </Layout>


                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ShulPage